exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-language-tsx": () => import("./../../../src/pages/language.tsx" /* webpackChunkName: "component---src-pages-language-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-select-viewpoint-page-template-tsx": () => import("./../../../src/templates/selectViewpointPageTemplate.tsx" /* webpackChunkName: "component---src-templates-select-viewpoint-page-template-tsx" */),
  "component---src-templates-site-map-page-template-tsx": () => import("./../../../src/templates/siteMapPageTemplate.tsx" /* webpackChunkName: "component---src-templates-site-map-page-template-tsx" */),
  "component---src-templates-taxation-page-template-tsx": () => import("./../../../src/templates/taxationPageTemplate.tsx" /* webpackChunkName: "component---src-templates-taxation-page-template-tsx" */)
}

